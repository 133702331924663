<template>
  <div>
    <FormulaBanner :title="title" :formulaCode="formulaCode" />
    <div class="index">
      <!-- 表单 -->
      <div>
        <div class="top_nav"><span></span>{{ title }}</div>
        <div class="formBody">
          <!-- 表单 -->
          <van-form @submit="onSubmit">
            <MyPatient
              :memberId="memberId"
              :selectValue="selectValue"
              @change="userChange"
            />
            <van-field
              v-model="nianling"
              :rules="[{ required: true, message: '请输入' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">年龄 <i>*</i></span>
                  <!--                  <van-radio-group v-model="radio" direction="horizontal">-->
                  <!--                    <van-radio name="1">男</van-radio>-->
                  <!--                    <van-radio name="2">女</van-radio>-->
                  <!--                  </van-radio-group>-->
                  <span style="display: flex;">
                    <span class="information">
                      <van-cell-group class="vanCell">
                        <van-field
                          input-align="right"
                          type="Number"
                          v-model="nianling"
                          placeholder="请输入"
                        />
                      </van-cell-group>
                    </span>
                    <div class="unit"><span>岁</span></div>
                  </span>
                </div>
              </template>
            </van-field>
            <van-field
              v-model="xingbie"
              :rules="[{ required: true, message: '请选择' }]"
            >
              <template #input>
                <div class="titles">
                  <span class="titlesName">性别 <i>*</i></span>
                  <van-radio-group v-model="xingbie" direction="horizontal">
                    <van-radio name="1">男</van-radio>
                    <van-radio name="2">女</van-radio>
                  </van-radio-group>
               
                </div>
              </template>
            </van-field>
            <van-field  v-model="hospital">
              <template #input>
                <div class="titles">
                  <span class="titlesName">住护理院</span>
                  <van-radio-group direction="horizontal" v-model="hospital">
                    <van-radio name="0">是</van-radio>
                    <van-radio name="1">否</van-radio>
                  </van-radio-group>
                </div>
              </template>
            </van-field>
          
             <TemplateFiveList :list-data="listData" :selectValue="listSelect" :type="'check'"
                            @change="listChange"></TemplateFiveList>
            <div v-if="compute === true" style="margin: 16px;display: flex;justify-content: center;">
              <van-button
                class="formula-template-button"
                block
                type="info"
                native-type="submit"
                style="width:90px"
                >提交</van-button
              >
            </div>
          </van-form>

          <!-- 计算结果 -->
          <!-- <div class="CalculationResultsBox" v-if="countText || countText !== ''">
            <div class="CalculationResults"><span>计算结果</span></div>
            <div>
              <div class="CalculationDetail"><span></span>{{countText}}</div>
            </div>
          </div> -->
        </div>
      </div>
      <!-- 计算结果 -->
      <CalculationResults
        v-if="show"
        :countText="countText"
        :countTextList="countTextList"
      ></CalculationResults>
      <!-- 后台设置的值 -->
      <FormulaExplain :explain-data="tableData.explain"></FormulaExplain>
      <!-- 参考文献 -->
      <FormulaReferences
        :references-data="tableData.references"
      ></FormulaReferences>
    </div>

    <van-popup v-model="showPicker" position="bottom">
      <van-picker
        title="应激指数"
        show-toolbar
        :columns="columns"
        @confirm="onConfirm"
        @cancel="onCancel"
      />
    </van-popup>
  </div>
</template>

<script>
import TemplateFiveList from "@/components/Template5/TemplateFiveList";
import MyPatient from "@/components/MyPatient.vue";
import FormulaBanner from "@/components/FormulaBanner.vue";
import { Toast } from "vant";
import { getAdmins } from "@/utils/adminDate";
import { setById } from "@/service/base";
import {
  getFormula,
  getFormulaHuiXian,
  postFormula,
} from "@/service/BodyMassIndex_api";
import FormulaExplain from "@/components/template1/FormulaExplain";
import FormulaReferences from "@/components/template1/FormulaReferences";
// import TemplateTreeList from "@/components/Template3/TemplateTreeList";
import CalculationResults from "@/components/CalculationResults";
export default {
  name: "medicalSearch",
  data() {
    return {
      compute:true,
      show: false,
      countTextList: [],
      checkStatus: false,
      listSelect: [],
      listSelect2: [],
      listSelect3: [],
      listData: [
         {
          title: '基础疾病',
          columns: ["肿瘤疾病","肝脏疾病","充血性心力衰竭","脑血管疾病","肾脏疾病"],
          fenzhi: [30,20,10,10,10] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
         {
          title: '体征',
          columns: ["意识状态改变","呼吸频率≥30/min","收缩压<90mmHg","体温<35°C或≥40°C","脉搏≥125/min"],
          fenzhi: [20,20,20,15,10] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
        {
          title: '实验室及影像学检查',
          columns: ["动脉血pH<7.35","尿素氮>30mg/dL或11mmol/L","钠<130mmol/L","葡萄糖≥250mg/dL或14mmol/L","红细胞比容<30%","PaO2<60mmHg","胸腔积液"],
          fenzhi: [30,20,20,10,10,10,10] //分值顺序必须和选项顺序保持一致！！！！！！！！！！！！！！！
        },
      ],
      formulaCode: "",
      shengao: "",
      yingjizhishu: "",
      title: "",
      memberId: "1111",
      selectValue: "",
      username: "",
      value: "",
      showPopover: false,
      showPicker: false,
      columns: ["正常或轻伤", "中度创伤", "重度创伤"],
      nianling: "",
      xingbie: null,
      hospital:null,
      tableData: {},
      countText: "",
      patientId: null,
      result: null,
      yingjizhishuzhi: "",
      tizhong: "",
      xueqingnanongdu: "",
    };
  },
  components: {
    FormulaBanner,
    MyPatient,
    FormulaExplain,
    FormulaReferences,
    // TemplateTreeList,
    CalculationResults,
    TemplateFiveList
  },
  async created() {
    await this.test();
    await this.getTableData();
  },
  watch: {
    xingbie: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
    nianling: {
      immediate: true,
      handler: function() {
        this.show = false;
      },
    },
  },
  methods: {
    // listChange(result) {
    //   this.listSelect = result;
    //   this.show = false;
    // },
      listChange(result, fenzhi) {
      this.show=false
      this.listSelect = result //结果数组
      this.fenzhiList = fenzhi  //分值数组
      console.log(result)
    },
    coumputTetxt() {
      let fenshu = 0;
      fenshu += Number(this.nianling);
      if (this.xingbie == 2) fenshu -= 10;
      if(this.hospital==0)   fenshu += 10
      function calculateScore(data, select) {
        select.value.forEach(item => {
          const index = data.columns.indexOf(item);
          if (index !== -1) {
            fenshu += data.fenzhi[index];
          }
        });
      }

       this.listSelect.forEach(value => {
        const data = this.listData.find(d => d.title === value.title);
        if (data) {
          calculateScore(data, value);
        }
      });
      // fenzhi = this.listSelect
      //   .map((item) => {
      //     const list = this.listData.filter((row) => row.title === item);
      //     return list && list.length > 0 ? list[0].value : null;
      //   })
      //   .filter((title) => title !== null);
      // for (let i = 0; i < fenzhi.length; i++) {
      //   fenshu += fenzhi[i];
      // }
      console.log("分数",fenshu);
      if (fenshu <= 50) {
        this.countText = fenshu + "分 危险级别Ⅰ";
      } else if (fenshu >= 51 && fenshu <= 70) {
        this.countText = fenshu + "分 危险级别Ⅱ";
      } else if (fenshu >= 71 && fenshu <= 90) {
        this.countText = fenshu +
          "分 危险级别Ⅲ";
      } else if (fenshu >= 91 && fenshu <= 130) {
        this.countText = fenshu + "分 危险级别Ⅳ";
      } else if (fenshu > 130) {
        this.countText = fenshu + "分 危险级别Ⅴ";
      }
      this.result = fenshu;
    },
    async test() {
      //像接口头部传用户信息
      let arrays = await getAdmins(
        this.$route.query.memberId,
        this.$route.query.channel
      );
      this.channel = arrays.os;
      this.version = arrays.version;
      this.ip = arrays.ip;
      await setById(arrays);
    },
    async getTableData() {
      const data = {
        channel: this.$router.currentRoute.query.channel,
        code: "HSUATTAS",
      };
      const res = await getFormula(data.channel, data.code);
      if (res.code === 0) {
        this.tableData = res.data;
        this.formulaCode = res.data.code;
        this.title = res.data.chineseName;
      }
      if (
        this.$router.currentRoute.query.location === "YXGS_RECORD_LIST" &&
        this.$router.currentRoute.query.logId
      ) {
        // this.compute = false
        const data = {
          id: this.$router.currentRoute.query.logId,
        };
        const res = await getFormulaHuiXian(data);
        if (res.code === 0) {
          this.nianling = res.data.content.nianling.value;
          this.xingbie = res.data.content.xingbie.value === "男" ? 1 : 2;
          this.hospital= res.data.content.hospital.value === "是" ? 0 : 1;
          this.listSelect = res.data.content.value;
          if (res.data.patientId)
            this.selectValue = {
              id: res.data.patientId,
              name: res.data.patientName,
            };
          this.coumputTetxt();
        }
        this.$nextTick(() => {
          this.show = true;
        });
      }
    },
    calculateAge(birthDateString) {
      const today = new Date();
      const birthDate = new Date(birthDateString);
      let age = today.getFullYear() - birthDate.getFullYear();
      // 如果今天的日期还没到生日月份和日期，则年龄减一
      if (
        today.getMonth() < birthDate.getMonth() ||
        (today.getMonth() === birthDate.getMonth() &&
          today.getDate() < birthDate.getDate())
      ) {
        age--;
      }
      return age;
    },
    // 开参考文献链接
    openLink(u) {
      if (!u) {
        return;
      }
      //获取系统版本
      let userAI = navigator.userAgent;
      const isAndroid =
        userAI.indexOf("Android") > -1 || userAI.indexOf("Linux") > -1; //Android终端
      const isIOS = !!userAI.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/); // ios终端
      if (isAndroid) {
        // alert('安卓操作系统')
        // 这个是安卓操作系统
        window.open(u);
      }
      if (isIOS) {
        // 这个是ios操作系统
        // alert('ios操作系统')
        window.location.href = u;
      }
    },
    yingjiClick() {
      this.showPicker = true;
    },
    onConfirm(value) {
      this.yingjizhishu = value;
      this.showPicker = false;
    },
    onCancel() {
      this.showPicker = false;
    },
    async onSubmit() {
      this.coumputTetxt();
      const data = {
        id:
          this.$router.currentRoute.query.location === "YXGS_RECORD_LIST"
            ? this.$router.currentRoute.query.logId
            : null,
        patientId: this.patientId,
        columnCode: this.tableData.columnCode,
        formulaCode: this.tableData.code,
        channel: this.$router.currentRoute.query.channel,
        location: this.$router.currentRoute.query.location,
        content: {
          value: this.listSelect,
          nianling: { value: this.nianling, unit: "岁" },
          xingbie: { value: this.xingbie == 1 ? "男" : "女" },
          hospital:{value:this.hospital==0? "是":"否"}
        },
        result: {
          value: this.result,
          result: this.countText,
          DisplayResults:this.countText
        },
      };
      const res = await postFormula(data);
      if (res.code === 0) {
        Toast.success("提交成功");
        this.show = true;
      } else {
        Toast.fail(res.message);
      }
    },
    userChange(data) {
      if (data.birthday && data.birthday !== "") {
        this.nianling = this.calculateAge(data.birthday);
      } else {
        this.nianling = "";
      }
      this.xingbie = data.sex;
      this.patientId = data.id;
      this.selectValue = data;
    },
  },
};
</script>

<style scoped lang="scss">
.index {
  background: #f6f6f6;
  padding: 10px 15px;
  min-height: 100vh;
  .StandardDescription {
    margin-top: 10px;
    .top_nav {
      font-size: 16px;
      color: #333333;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: start;
      margin-bottom: 10px;
      span {
        display: inline-block;
        width: 5px;
        height: 15px;
        background: #259beb;
        margin-right: 5px;
      }
    }
    .StandardDeatil {
      width: 100%;
      padding: 15px 10px;
      //   height: 50px;
      background-color: #fff;
      box-sizing: border-box;
    }
  }
  .top_nav {
    font-size: 16px;
    color: #333333;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: start;
    margin-bottom: 10px;
    span {
      display: inline-block;
      width: 5px;
      height: 15px;
      background: #259beb;
      margin-right: 5px;
    }
  }
  .formBody {
    background-color: #fff;
    padding-bottom: 10px;
    .CalculationResultsBox {
      padding: 0 8px;
      .CalculationResults {
        font-size: 16px;
        color: #333333;
        font-weight: bold;
        display: flex;
        justify-content: center;
        margin-bottom: 10px;
      }
      .CalculationDetail {
        font-size: 15px;
        color: #333333;
        // font-weight: bold;
        display: flex;
        align-items: center;
        justify-content: start;
        margin-bottom: 10px;
        background-color: #f6f6f6;
        // height: 16px;
        span {
          display: inline-block;
          width: 3px;
          height: 24px;
          background: #fe9712;
          margin-right: 5px;
        }
      }
    }
  }
  .titles {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    height: 50px;
    border-bottom: 1px solid #f0f0f0;
    padding: 0 10px;
    box-sizing: border-box;
    .titlesName {
      font-size: 16px;
      i {
        color: red;
      }
    }
    .information {
      font-size: 16px;
      margin-right: 4px;
      .PleaseSelect {
        color: #b9b9b9;
      }
      .vanCell {
        width: 70px;
        padding: 0;
        height: 100%;
      }
    }
    .vBotton {
      width: 60px;
    }
    .unit {
      height: 50px;
      //   line-height: 50px;
      display: flex;
      align-items: center;
      font-size: 16px;
      width: 20px;
    }
    .vanIcon {
      font-size: 13px;
      color: #a8a8a8;
      font-weight: 650;
    }
  }
}
::v-deep .van-cell {
  padding: 0px 0px;
}
::v-deep .van-field__control {
  height: 50px;
  font-size: 16px;
}
::v-deep .van-field__error-message {
  position: absolute;
  top: 30px;
  left: 10px;
}
.StandardDeatil2 {
  width: 100%;
  padding: 15px 10px;
  padding-bottom: 1px;
  //   height: 50px;
  background-color: #fff;
  box-sizing: border-box;
  .references {
    margin-bottom: 15px;
  }
}
::v-deep p {
  padding: 0;
  margin: 0;
}
</style>
